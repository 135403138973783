import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import {forumLeagues, UserRoles} from "../constants";
import { SideBarItemType, ForumNavigationItem } from "../types";

import premierLeagueLogo from '../../public/assets/images/premier-league-logo.png';
import bundesligaLogo from '../../public/assets/images/bundesliga-logo.png';
import laLigaLogo from '../../public/assets/images/laliga-logo.png';
import ligue1Logo from '../../public/assets/images/ligue-1-logo.png';
import serieALogo from '../../public/assets/images/serie-a-logo.png';
import uefaChampionsLeagueLogo from '../../public/assets/images/uefa-champions-league-logo.png';
import uefaEuropaLeagueLogo from '../../public/assets/images/uefa-europa-league-logo.png';
import majorLeagueSoccerLogo from '../../public/assets/images/major-league-soccer-logo.png';



export const forumNavigationArray: ForumNavigationItem[] = [
  {
    text: 'UEFA Champions League',
    link: `/forum/football/${encodeURIComponent(forumLeagues.championsLeague)}/1`,
    logo: uefaChampionsLeagueLogo,
    description: 'The UEFA Champions League is the most prestigious and arguably the most difficult football competition to qualify for. With so much at stake, the pressure on players is immense, and so are the rewards.\n' +
      'To reach this stage of the competition, clubs have had to successfully navigate qualifying rounds and national leagues, but it’s not as simple as that. The qualifying rounds alone have been home to some of the biggest shocks in footballing history.\n' +
      'The UEFA Champions League pits Europe’s top football clubs against each other in an annual contest of skill, strategy, and stamina. 32 teams begin as a group with 4 groups of 8 teams with every team playing each other once and the top two teams from each group advancing to a playoff round where they are paired up again:The 16 remaining teams compete in another group stage with 4 groups again but now with only 4 teams Each team playing each other once again but this time home and away instead of just once . Again The top two from these groups advance to a final knockout phase where they compete against each'
  },
  {
    text: 'UEFA Europa League',
    link: `/forum/football/${encodeURIComponent(forumLeagues.europaLeague)}/1`,
    logo: uefaEuropaLeagueLogo,
    description: 'The UEFA Europa League is an annual football competition organized by the Union of European Football Associations (UEFA) and is the second most prestigious continental football competition in Europe, behind the UEFA Champions League. The tournament was re-branded as the UEFA Europa League, from its previous name of UEFA Cup, in the 2015-16 season. While it’s not quite as well known or prestigious as its older brother, the tournament is a great opportunity for football clubs to bring home some silverware.\n' +
      'The group phase of the competition involves teams being split into two sets of groups – one containing A, B and C ranked teams and another D and unranked teams. Each team plays double games against their group opponents, with the winner going through to the knockout stage. Typically there are four qualifiers from each group that go on to a knockout round of 32 teams. From there, it is a straight knock out until we have our eventual winners!'
  },
  {
    text: 'Premier League',
    link: `/forum/football/${encodeURIComponent(forumLeagues.premierLeague)}/1`,
    logo: premierLeagueLogo,
    description: 'The Premier League is the top professional football league in England, and it is one of the most popular sports leagues in the world. It was founded in 1992, and it has been contested every year since. It has 20 member clubs, each competing for the title of champion. The Premier League was founded in 1992 with 10 teams, but it now has 20 teams. The only two clubs to have never been relegated are Manchester United and Chelsea. The current champions of The Premier League are Manchester City. The number of teams in the EPL has changed over time, with a high of 20 teams in 1992/1993, and a low of 12 teams in 1995/1996. The number has settled on 20 since 2003/2004. To qualify for the UEFA Champions League one would need to be in the top four of the Premier League, while the fifth and sixth place teams go to the UEFA Europa League. The bottom three teams in The Premier League are demoted to The Championship, while the top two teams are promoted to The Premier League automatically. For the third spot in The Premier League, the third through sixth team in the Championship play a knockout stage and the winner is promoted to The Premier League.'
  },
  {
    text: 'Bundesliga',
    link: `/forum/football/${encodeURIComponent(forumLeagues.bundesliga)}/1`,
    logo: bundesligaLogo,
    description: 'Bundesliga is a professional football league in Germany. Bundesliga is Germany’s\n' +
      'top football competition. A total of 18 teams play in the bundesliga in a season\n' +
      'which starts in August and ends in May. All of the clubs playing in the Bundesliga\n' +
      'and the Bundesliga 2 qualify for the DFB-Pokkal. Each team plays a total of 34\n' +
      'matches while playing each team twice once on their home ground and once on\n' +
      'the opponent’s territory. At the end of the season after all the matches are played\n' +
      'the team with the most points wins the league and qualifies for the DFL Super\n' +
      'Cup. The last two teams after the regular season are relegated to Bundesliga 2\n' +
      'which is the second division of the Bundesliga. The top two teams in Bundesliga 2\n' +
      'are promoted to the Bundesliga (first division) in the following season. The third\n' +
      'last team in Bundesliga plays a relegation playoff against the third team in\n' +
      'Bundesliga 2 to decide who gets to play in the top division the next season. Teams\n' +
      'that finish in the top four of Bundesliga qualify for UEFA Champions League, while\n' +
      'the fifth and sixth places qualify for the UEFA Europa League, while the seventh\n' +
      'place goes to the UEFA conference league playoffs.'
  },
  {
    text: 'La Liga',
    link: `/forum/football/${encodeURIComponent(forumLeagues.laLiga)}/1`,
    logo: laLigaLogo,
    description: 'La Liga, or The League, is the top football division in Spain. It was founded in 1929 as the "League of National Antifascist Athletes" and is currently sponsored by BBVA, a Spanish financial group. La Liga is contested by 20 teams, with the top three teams automatically qualifying for the UEFA Champions League group stage, and the fourth-placed team qualifying for the UEFA Europa League group stage. The other five teams are relegated to the Segunda División. The most successful clubs in La Liga were Real Madrid and Barcelona, which have won the league 32 times each. La Liga is the world\'s most popular football league, and the world\'s most profitable football league. \n' +
      'The 20 teams that currently make up La Liga are: Las Palmas, Eibar, Girona and Valladolid are the four teams to have been promoted to La Liga after being promoted from the Segunda División in the 2018–19 season. The teams relegated from La Liga after the 2018–19 season were Real Valladolid, Real Sociedad, Levante, and Getafe. Barcelona, Real Madrid, and Athletic Bilbao are the only teams to have played every season in La Liga since its inception in 1929. La Liga is the world\'s most profitable football league, and the world\'s most popular football league. It was the first league to be broadcast on television around the world.'
  },
  {
    text: 'Ligue 1',
    link: `forum/football/${encodeURIComponent(forumLeagues.ligue1)}/1`,
    logo: ligue1Logo,
    description: 'Ligue 1 is a professional football league that was founded in 1932. The league is contested by 20 clubs each season and is currently operated by the French Football Federation (FFF). Ligue 1 is widely considered to be one of the top football leagues in the world, along with the English Premier League and Spanish La Liga. \n' +
      'The format of Ligue 1 is very simple: each team plays the other teams in the league twice, once at home and once away. This means that each team plays a total of 38 matches over the course of the season. \n' +
      'The team that finishes at the top of the table at the end of the season is crowned the champion and is awarded the Ligue 1 trophy. The bottom three teams are relegated to the second division, known as Ligue 2. \n' +
      'The champion of Ligue 2 is promoted to Ligue 1, and the bottom two teams are relegated to the third division. This format ensures that there is always a high level of competition in Ligue 1.'
  },
  {
    text: 'Serie A',
    link: `forum/football/${encodeURIComponent(forumLeagues.serieA)}/1`,
    logo: serieALogo,
    description: 'Serie A is the top professional football league in Italy. The league was founded in 1898 and is contested by 20 teams. Serie A is considered to be one of the best football leagues in the world and is often ranked as the second-best football league behind the English Premier League. \n' +
      '\n' +
      'The format of Serie A has changed several times in its history. The league currently operates on a system of promotion and relegation, whereby the bottom three teams are relegated to Serie B and the top three teams from Serie B are promoted to Serie A. \n' +
      '\n' +
      'The Serie A season usually goes live from August to May and each team plays the other 19 teams twice making it a total of 38 games played by each of the teams. The top four teams at the end of the season qualify for the UEFA Champions League, while the fifth and sixth-placed teams qualify for the UEFA Europa League.\n' +
      '\n' +
      'Like the other top flight football leagues in the world, the bottom three teams in Serie A are demoted to the lower division Serie B with the top teams in Serie B making their way into Serie A.'
  },
  {
    text: 'Major League Soccer',
    link: `forum/football/${encodeURIComponent(forumLeagues.majorLeagueSoccer)}/1`,
    logo: majorLeagueSoccerLogo,
    description: 'Major League Soccer (MLS) is the top professional soccer league in the United States and Canada, which got founded in 1996 after USA hosted the world cup in 1994.\n' +
      'The league has a total of 28 teams divided onto western and eastern conferences. Each team plays 34 matches 17 at home and 17 away. They play the opponents in the same conference twice while they play 8 teams in the opposing conference once. At the end of the season the clubs that finish in the top 7 in both conferences qualify for the MLS Cup Playoffs.\n' +
      'A single-entity structure is employed in the league in which MLS owns all of the teams, players, and licenses. This allows MLS to control player salaries and ensure that each team is competitive. Unlike other leagues though there is no concept of demoting in the Major League Soccer even though there are lower division leagues in the United States and Canada, thus people will see the same clubs competing in the league each season. Although to make it fair, the lower ranked teams get first picks in the drafts for the following year so they can compete with the other clubs.'
  }
]

export const SideBarArray: SideBarItemType[] = [
  {
    icon: HomeIcon,
    text: 'Home',
    link: '/',
    userRoles: [UserRoles.admin, UserRoles.user],
  },
  {
    icon: ArticleIcon,
    text: 'Forum',
    link: '/forum/football',
    userRoles: [UserRoles.admin, UserRoles.user],
  }
];
