import styles from './header.module.css';

import Image from "next/image";
import AvatarIcon from '../../public/assets/images/male-icon-1.jpg';

import MenuIcon from '@mui/icons-material/Menu';

import sportsFeverLogo from '../../public/assets/logos/Sports Fever-logos_black.png';
import keepingYouPostedLogo from '../../public/assets/logos/keeping-you-posted.png';
import Link from "next/link";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {initialValues, loginSchema} from "../../common/validation/login";
import {Form, Formik} from "formik";
import {renderTextField} from "../../common/form/components";
import {LoginApiInterface} from "../../common/types/api/login";
import {axiosClient} from "../../common/api";
import {NotificationManager} from "react-notifications";
import {initialUserState, setUser} from "../../store/user";
import {AxiosError} from "axios";

interface PropsInterface {
  setSideBarOpen: () => void;
  sideBarOpen: boolean;
}

export default function Header(props: PropsInterface) {
  const [showUserInformation, setShowUserInformation] = useState(false);
  const name = useSelector((state: any) => state.user.name);
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    const data: LoginApiInterface = { ...values };

    try {
      const response = await axiosClient.post('/auth/login', data);

      NotificationManager.success('Login Successful', 'Sign In', 3000);

      const { foundUser, token } = response.data.data;

      dispatch(setUser({ ...foundUser, token }));
    } catch (error: any) {
      const axiosError: AxiosError = error;
      const data = axiosError?.response?.data as any;
      NotificationManager.error(data?.error, data?.errorMessage, 3000);
    }
  }

  const renderUserInformationModal = () => {
    const modalContent = name ?
      (
        <>
          <div className={styles.UserInformationNameDiv}>
            {name}
          </div>
          <div
            className={styles.SignOutDiv}
            onClick={async () => {
              dispatch(
                setUser({
                  ...initialUserState,
                  _id: initialUserState.id,
                })
              );

              axiosClient.defaults.headers.common['Authorization'] = '';
            }}
          >
            {'Sign Out'}
          </div>
        </>
      ) :
      (
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={async (values, { resetForm }) => {
            await onSubmit(values);
            resetForm();
          }}
        >
          <Form className={styles.LoginForm}>
            {renderTextField('email', 'text', 'Email', 'Email', true)}
            {renderTextField('password', 'password', 'Password', 'Password', true)}
            <button className={styles.LoginButton} type={'submit'}>Sign In</button>
          </Form>
        </Formik>
      )

    if(showUserInformation) return (
      <>
        <div className={styles.ArrowUp}/>
        <div className={name ? styles.UserInformationModalDivSignedIn : styles.UserInformationModalDiv}>
          {modalContent}
        </div>
      </>
    );
  }

  return (
    <div className={styles.HeaderMain}>
      <div className={props.sideBarOpen ? styles.MenuToggleHeaderSideBarOpen : styles.MenuToggleHeader} onClick={props.setSideBarOpen}>
        <MenuIcon/>
      </div>
      <Link href={'/'}>
        <a aria-label={'Home Page'}>
          <div className={styles.SportsFeverHeaderLogo}>
            <Image className={styles.HeaderImage} src={sportsFeverLogo} alt={'Sports Fever Logo'} layout={'fixed'} height={150} width={150}/>
          </div>
        </a>
      </Link>
      <Link href={'/'}>
        <a aria-label={'Home Page'}>
          <div className={styles.KeepingYouPostedHeaderLogo}>
            <Image className={styles.HeaderImage} src={keepingYouPostedLogo} alt={'Keeping You Posted Logo'} layout={'fixed'} height={100} width={350}/>
          </div>
        </a>
      </Link>
      <div className={styles.UserInformationDiv}>
        <div className={styles.AvatarDiv} onClick={() => setShowUserInformation(!showUserInformation)}>
          <Image className={styles.AvatarIcon} src={AvatarIcon} alt={'Avatar Icon'} layout={'fixed'} height={35} width={35}/>
        </div>
        {renderUserInformationModal()}
      </div>
    </div>
  );
}