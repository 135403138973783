export enum UserRoles {
  admin = 'admin',
  user = 'user'
}

export enum PageNames {
  home = 'home',
  forum = 'forum',
  register = 'register'
}

export enum forumLeagues {
  premierLeague = 'premier-league',
  bundesliga = 'bundesliga',
  laLiga = 'la-liga',
  ligue1 = 'ligue-1',
  serieA = 'serie-a',
  majorLeagueSoccer = 'major-league-soccer',
  championsLeague = 'champions-league',
  europaLeague = 'europa-league'
}

export const forumInformation = {
  [forumLeagues.championsLeague]: {
    title: 'Champions League Forum'
  },
  [forumLeagues.europaLeague]: {
    title: 'Europa League Forum'
  },
  [forumLeagues.premierLeague]: {
    title: 'Premier League Forum'
  },
  [forumLeagues.bundesliga]: {
    title: 'Bundesliga Forum'
  },
  [forumLeagues.laLiga]: {
    title: 'La Liga Forum'
  },
  [forumLeagues.ligue1]: {
    title: 'Ligue 1 Forum'
  },
  [forumLeagues.serieA]: {
    title: 'Serie A Forum'
  },
  [forumLeagues.majorLeagueSoccer]: {
    title: 'Major League Soccer Forum'
  },
}