import styles from './leftSideBar.module.css';

import MenuIcon from '@mui/icons-material/Menu';

import Link from "next/link";

import { SideBarArray } from "../../common/navigation";
import {PageNames} from "../../common/constants";
import {useDispatch, useSelector} from "react-redux";
import {initialUserState, setUser} from "../../store/user";
import {axiosClient} from "../../common/api";

interface PropsInterface {
  pageName: PageNames;
  sideBarOpen: boolean;
  setSideBarOpen: () => void;
}

export default function LeftSideBar(props: PropsInterface) {
  const email = useSelector((state: any) => state.user.email);
  const dispatch = useDispatch();

  const renderSideBarList = () => {
    return SideBarArray.map((sideBarElement, index) => {
      return (
        <li className={styles.LeftSideBarItemText} key={index}>
          <Link href={sideBarElement.link}>
            <a aria-label={sideBarElement.text} className={styles.LeftSideBarItemDiv}>
              <div className={styles.LeftSideBarItemIcon}>
                {<sideBarElement.icon />}
              </div>
                {sideBarElement.text}
            </a>
          </Link>
        </li>
      );
    })
  }

  const renderLoginDiv = () => {
    return email ? (
      <div className={styles.LoginDiv}>
        <div
          className={styles.LoginDivText}
          onClick={async () => {
            dispatch(
              setUser({
                ...initialUserState,
                _id: initialUserState.id,
              })
            );

            axiosClient.defaults.headers.common['Authorization'] = '';
          }}
        >
          {"Sign Out"}
        </div>
      </div>
    ) : (
      <div className={styles.LoginDiv}>
        <Link href={"/login"}>
          <a className={styles.LoginDivText}>{"Sign In"}</a>
        </Link>
        <Link href={"/register"}>
          <a className={styles.LoginDivText}>{"Register"}</a>
        </Link>
      </div>
    );
  }

  return (
    <div className={props.sideBarOpen ? styles.LeftSideBarMainOpen : styles.LeftSideBarMain}>
      <div className={props.sideBarOpen ? styles.MenuToggleHeaderSideBarOpen : styles.MenuToggleHeader} onClick={props.setSideBarOpen}>
        <MenuIcon/>
      </div>
      <div className={styles.LeftSideBarItems}>
        <ul>
          {renderSideBarList()}
        </ul>
      </div>
      {renderLoginDiv()}
    </div>
  );
}