import styles from "./form.module.css";
import {ErrorMessage, Field} from "formik";

const renderError = (message: string) => <p className={styles.TextFieldError}>{message}</p>;

export const renderTextField = (name: string, type: string, placeHolder: string, label: string, minify?: boolean, long?: boolean) => {
  return (
    <div className={minify ? styles.TextFieldMainMinifiedDiv : styles.TextFieldMainDiv}>
      <div className={minify ? styles.TextFieldLabelMinifiedDiv : styles.TextFieldLabelDiv}>
        <label className={styles.TextFieldLabel}>
          {label}
        </label>
      </div>
      <div className={styles.TextFieldDiv}>
        <Field
          name={name}
          type={type}
          className={long ? styles.TextFieldLong : minify ? styles.TextFieldMinified : styles.TextField}
          placeholder={placeHolder}
        />
        <ErrorMessage name={name} render={renderError} />
      </div>
    </div>
  )
}

export const renderTextArea = (name: string, type: string, placeHolder: string, label: string, minify?: boolean) => {
  return (
    <div className={minify ? styles.TextFieldMainMinifiedDiv : styles.TextFieldMainDiv}>
      <div className={styles.TextFieldLabelDiv}>
        <label className={styles.TextFieldLabel}>
          {label}
        </label>
      </div>
      <div className={styles.TextAreaDiv}>
        <Field
          name={name}
          as={'textarea'}
          type={type}
          className={minify ? styles.MinifiedTextArea : styles.TextArea}
          placeholder={placeHolder}
        />
        <ErrorMessage name={name} render={renderError} />
      </div>
    </div>
  )
}

export const renderDropDownField = (name: string, options: string[] | number[], placeHolder: string, label: string) => {
  return (
    <div className={styles.TextFieldMainDiv}>
      <div className={styles.TextFieldLabelDiv}>
        <label className={styles.TextFieldLabel}>
          {label}
        </label>
      </div>
      <Field
        name={name}
        as={'select'}
        className={styles.TextField}
        placeholder={placeHolder}
      >
        <option value={""}>{placeHolder}</option>
        {options.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} render={renderError} />
    </div>
  )
}